import React from "react";
import Helmet from 'react-helmet';
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { openPopupWidget } from "react-calendly";

import "@assets/scss/App.scss";
import "./assets/team.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import ContentHeader from "@components/modules/content-header/";
import ArticleText from "@components/article-text/only-article-text-html";
import OurPeopleCardJobTitle from "@components/our-people/_ourPeopleCardJobTitle";
import OurPeopleCardPhone from "@components/our-people/_ourPeopleCardPhone";
import OurPeopleCardEmail from "@components/our-people/_ourPeopleCardEmail";
import OurPeopleCardFacebook from "@components/our-people/_ourPeopleCardFacebook";
import OurPeopleCardTwitter from "@components/our-people/_ourPeopleCardTwitter";
import OurPeopleCardLinkedIn from "@components/our-people/_ourPeopleCardLinkedIn";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";
import Img from "gatsby-image";
import MoreTeamMembers from "@components/modules/more-team-members";
import ImageTextModule from "@components/modules/image-text/";
import CalendlyButtonLink from "@components/book-video-call/";

const TeamPage = ({pageContext, data}, props) => {
    return(
        <Layout>
                <SEO
                    title={`Our people | ${data.strapiTeams.Name}`}
                    description={`Crux Careers the top estate agent recruitment agency and recruitment advisor to the property industry invites you to meet ${data.strapiTeams.Name} and know more details here.`}
                    image={data.strapiTeams.Image ? data.strapiTeams.Image.childImageSharp.fluid : null}
                />
                <Helmet 
                    bodyAttributes={{
                        class: 'white-bg team-details'
                    }}
                />
                <Header />
                <Container>
                    <Row>
                        <Col xs={12} lg={{span:5, offset: 1, order: 2}} className="our-people-card__job__img mt-lg-90 mt-xl-142">
                            {data.strapiTeams.Image && <Img fluid={data.strapiTeams.Image.childImageSharp.fluid} />}
                        </Col>
                        <Col xs={12} lg={6}>
                            <ContentHeader
                                Title={data.strapiTeams.Name}
                                pageName_1="About"
                                pageLink_1="/about"
                                lastClass_1=""
                                pageName_2="Our People"
                                pageLink_2="/about/our-people"
                                lastClass_2=""
                                pageName_3={data.strapiTeams.Name}
                                lastClass_3="last"
                                sectionContainerClass=" mt-24 mb-0 mt-md-32 mt-xl-82"
                            />

                            {data.strapiTeams.JobTitle && <OurPeopleCardJobTitle jobTitle={data.strapiTeams.JobTitle} jobTitleClass="our-people-card__job__title mb-5 mb-md-20" />}
                            <ul className="our-people-card__list list-inline mb-0">
                                {data.strapiTeams.PhoneNumber && <li className="list-inline-item"><OurPeopleCardPhone phone={data.strapiTeams.PhoneNumber} /></li>}
                                {data.strapiTeams.Email && <li className="list-inline-item">
                                    <OurPeopleCardEmail
                                        email={data.strapiTeams.Email}
                                        emailLabel="Email"
                                        memberName={`${data.strapiTeams.Name}`}
                                    />
                                </li>}
                                <li className="list-inline-item list-inline-socials">
                                    {data.strapiTeams.FacebookLink && <OurPeopleCardFacebook socialLink={data.strapiTeams.FacebookLink} />}
                                    {data.strapiTeams.TwitterLink && <OurPeopleCardTwitter socialLink={data.strapiTeams.TwitterLink} />}
                                    {data.strapiTeams.LinkedInLink && <OurPeopleCardLinkedIn socialLink={data.strapiTeams.LinkedInLink} />}
                                </li>
                            </ul>
                            {data.strapiTeams?.CalendlyLink ?
                            <CalendlyButtonLink
                                label={data.strapiTeams.BookVideoLabel}
                                url={data.strapiTeams.CalendlyLink}
                                btnClass="btn btn-primary d-md-inline-block mt-md-37"
                            /> : ''}
                            <ArticleText
                                html={data.strapiTeams.Text}
                                sectionClass=" mt-33 mb-34 mt-md-52 mb-md-60 mb-xl-96"
                            />
                        </Col>
                    </Row>
                </Container>
                <MoreTeamMembers excludeId={data.strapiTeams.strapiId} />
                <ImageTextModule
                    imgUrl={data.file.childImageSharp.fluid}
                    moduleData={
                        [
                            {
                                id: 1,
                                imageUrl: 'young-woman.jpg',
                                videoUrl: '',
                                Title: '',
                                Text: '',
                                Buttons: [
                                    {
                                        id: 'dfgs',
                                        Label: 'Upload Your CV',
                                        externalLink: null,
                                        page: null,
                                        targetLink: '/candidates/upload-your-cv'
                                    }
                                ]
                            }
                        ]
                    }
                />
                <PopularSearch {...props} />
                <Footer />
        </Layout>
    )
}

export default TeamPage

export const query = graphql`
    query TeamPage($id: String!) {
        strapiTeams(id: {eq: $id}, Publish: {eq: true}) {
            id
            strapiId
            BookVideoLabel
            Image {
                childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 500, cropFocus: CENTER, fit: COVER, background: "#fff") {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            JobTitle
            Name
            PhoneNumber
            ViewBioLabel
            Slug
            Text
            Email
            FacebookLink
            LinkedInLink
            TwitterLink,
            CalendlyLink
        }
        file(relativePath: { eq: "Candidate-Refer-A-Candidate-upload-CV.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
` 