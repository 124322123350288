import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import  "./assets/_index.scss";

import OurPeopleCardImg from "@components/our-people/_ourPeopleCardImg";
import OurPeopleCardName from "@components/our-people/_ourPeopleCardName";
import OurPeopleCardJobTitle from "@components/our-people/_ourPeopleCardJobTitle";
import OurPeopleCardPhone from "@components/our-people/_ourPeopleCardPhone";
import OurPeopleCardBio from "@components/our-people/_ourPeopleCardBioLink";

class MoreTeamMembers extends React.Component {
    render() {
        const teamMember = this.props.teamData.nodes;
        const teamMemberIgnoreId = this.props.excludeId ? this.props.excludeId : null;
        return (
            <section className={`section section-more-team-members${this.props.sectionClass}`}>
                <Container>
                    <Row>
                        <h3 className={this.props.sectionTitleClass}>{this.props.sectionTitle}</h3>
                    </Row>
                    <Row>
                        {teamMember.map((team, index) => {
                            if (teamMemberIgnoreId === team.strapiId) return null;
                            return (
                                <Col key={index} xs={12} md={6} xl={3} className={this.props.itemClass}>
                                    {team.Image && <OurPeopleCardImg imgNode={team.Image} imgClass={this.props.imgClass} bioLink={`/about/our-people/${team.Slug}`} />}
                                    <div className={this.props.textItemClass}>
                                        {team.Name && <OurPeopleCardName name={team.Name} titleClass={this.props.titleClass} bioLink={`/about/our-people/${team.Slug}`} />}
                                        {team.JobTitle && <OurPeopleCardJobTitle jobTitle={team.JobTitle} jobTitleClass={this.props.jobTitleClass} />}
                                        <ul className={this.props.listClass}>
                                            {team.PhoneNumber && <li className="list-inline-item"><OurPeopleCardPhone phone={team.PhoneNumber} /></li>}
                                            {team.ViewBioLabel && <li className="list-inline-item"><OurPeopleCardBio bioLabel={team.ViewBioLabel} bioLink={`/about/our-people/${team.Slug}`} /></li>}
                                        </ul>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        );
    }
}

// Specifies the default values for props:
MoreTeamMembers.defaultProps = {
    sectionClass: ' pt-33 pb-15 pt-md-52 pb-md-0 pt-xl-88 border-top-light',
    sectionTitle: 'More of our team',
    sectionTitleClass: 'section-title col-xs-12 mb-16 mb-md-29 mb-xl-43',
    itemClass: 'our-people-card-normal d-flex align-items-center flex-md-column align-items-md-start mb-25 mb-md-60 mb-xl-84',
    imgClass: 'our-people-card__img mb-md-33',
    textItemClass: 'our-people-card__text ml-20 ml-md-0',
    titleClass: 'our-people-card__title font-weight-bold mb-0 mb-md-9 mb-xl-1',
    jobTitleClass: 'our-people-card__job__title mb-5 mb-md-16',
    listClass: 'our-people-card__list list-inline mb-0'
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allStrapiTeams(filter: {Publish: {eq: true}}) {
                    nodes {
                        id
                        strapiId
                        Name
                        JobTitle
                        PhoneNumber
                        ViewBioLabel
                        Slug
                        Image {
                            childImageSharp {
                                fluid(quality: 80, maxWidth: 400, maxHeight: 500, cropFocus: CENTER, fit: COVER, background: "#fff") {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <MoreTeamMembers teamData={data.allStrapiTeams} excludeId={props.excludeId} />
        )}
    />
)